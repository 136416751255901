<template>
  <div>
    <div class="google-map" ref="googleMap"></div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

export default {
    data() {
        return {
            apiKey: 'AIzaSyA2MaerpeqYE9JPQlftb_w2TQFhrMlZqfA',
            google: null,
            map: null,
            allMarkers: [],
            closestMarkers: [],
        }
    },

    props: {
        stores: Array,
        userLocation: Object,
        userLocLat: Number,
        userLocLng: Number,
        zoomLevel: Number
    },

    computed: {
        storeLocations() {
            const locations = [];
            this.stores.forEach(store => {
                let iconColor = 'blue';
                if (store.name.includes('SM')) {
                    iconColor = 'red';
                }
                const location = { 
                    center: {lat: store.latitude, lng: store.longitude},
                    icon: require(`@/assets/images/marker-${iconColor}.png`)
                };
                locations.push(location);
            });
            return locations;
        },
    },

    watch: {
        stores() {
            this.initializeMap();
        },
        userLocLat() {
            this.initializeMap();
        },
        userLocLng() {
            this.initializeMap();
        },
        zoomLevel() {
            this.initializeMap();
        }
    },

    methods: {
        async initializeMap() {
            const mapContainer = this.$refs.googleMap;

            const googleMapsApiLoader = new Loader({
                apiKey: this.apiKey,
                version: "weekly",
                libraries: ["places"]
            });

            this.google = await googleMapsApiLoader;
            
            const mapConfig = {center: {lat: this.userLocation.lat, lng: this.userLocation.lng}, zoom: this.zoomLevel};

            await navigator.geolocation.getCurrentPosition((position) => {   
                mapConfig.center.lat = position.coords.latitude;
                mapConfig.center.lng = position.coords.longitude;
            });

            this.google.load()
                .then((google) => {
                    // create map
                    this.map = new google.maps.Map(mapContainer, mapConfig);
                    // create marker
                    this.storeLocations.forEach(location => {
                        const marker = new google.maps.Marker({ 
                            position: location.center, 
                            map: this.map,
                            icon: location.icon 
                        });
                        this.allMarkers.push(marker);
                    });     
                })
                .catch(err => {
                    console.log(err)
                });
            
        },
    },

    mounted() {        
        this.initializeMap()  
    },
}
</script>

<style lang="scss" scoped>
.google-map {
    height: 43rem;
}
</style>
